
import { ConsentString, decodeConsentString, encodeConsentString } from 'consent-string';
import { Utils } from './utils.js';
import { Ui } from './ui.js';

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
 */
if (typeof Object.assign != 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict';
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

// solve the incompatibility with prototype.js as detected on ralcitalia.com
//  in which prototype.js v. 1.6.0.2 overwrites the Array.prototype.reduce method.
// https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/issues/90
Array.prototype.reduce = function(callback, initialVal) {
    var accumulator = (initialVal === undefined) ? undefined : initialVal;
    for (var i = 0; i < this.length; i++) {
        if (accumulator !== undefined)
            accumulator = callback.call(undefined, accumulator, this[i], i, this);
        else
            accumulator = this[i];
    }
    return accumulator;
};

/**
 *  https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/
 */
(function() {
  var config = {}
  var CMP_ID = 96;
  var CMP_VERSION = '1.3.3';
  var isLoaded = false;
  var vendorList = {};
  var purposesList = {};
  var queue = [];
  var isConsentObtained = false;
  var JSON = window.JSON && window.JSON.parse ? window.JSON : window.JSON_bk;
  
  //
  // import queue and config
  //
  if (window.__cmp) {
    queue = window.__cmp.queue;
    for (var ck in window.__cmp.config) {
      if (window.__cmp.config[ck]) {
        config[ck] = window.__cmp.config[ck];
      }
    }
  }
  
  // (VI). Set the __cmp function to the CMP's full API implementation.
  window.__cmp = (function() {
    var commands = {};
    
    //
    // mandatory calls
    //
    commands.getVendorConsents = function(vendorIds, callback) {
      //console.log('*** getVendorConsents');
      if (isConsentObtained) {
        //console.log('consent is obtained.', vendorList);
        var consent = getConsentObject();
        Ui.setConsent(consent);
        Ui.showMiniLayer(onClickMiniLayer, config);
        
        callback && callback(consent, true);
      } else {
        //console.log('consent is not obtained.');
        //console.log('calling requireconsent');
        commands.requireConsent(null, function() {
          //console.log('callback for requireconsent: calling getVendorConsents again');
          commands.getVendorConsents(vendorIds, callback);
        });
      }
    }
    
    commands.getConsentData = function(consentStringVersion, callback) {
      if (isConsentObtained) {
        var ck_value = Utils.getPermanentCookie(config.cookie_name, config.ms_to_expire);
        var consent_string = ck_value.split('|')[0];
        
        // check della versione
        if (consentStringVersion) {
          var consentData = new ConsentString(consent_string);
          if (consentData.version != consentStringVersion) {
            callback(null, true);
            return;
          }
        }
        
        var vendorConsentData = {
          consentData: consent_string,
          gdprApplies: config.gdprApplies,
          hasGlobalScope: config.hasGlobalScope
        };
        
        callback(vendorConsentData, true);
      } else {
        commands.requireConsent(null, function() {
          commands.getConsentData(consentStringVersion, callback);
        });
      }
    }
    
    commands.ping = function(ignored, callback) {
      callback({
        gdprAppliesGlobally: config.gdprAppliesGlobally,
        cmpLoaded: isLoaded
      }, true);
    }
    
    //
    // optional calls
    //
    commands.getPublisherConsents = function(purposeIds, callback) {
      if (isConsentObtained) {
        var ck_value = Utils.getPermanentCookie(config.cookie_name, config.ms_to_expire);
        var consent_string = ck_value.split('|')[0];
        var consentData = new ConsentString(consent_string);
        
        var standardPurposeConsents = {};
        var customPurposeConsents = {};
        var purposeIds = purposeIds || [];
        for (var i = 0; i < config.purposes_allowed.length; i++) {
          var id = config.purposes_allowed[i];
          if (purposeIds.length == 0 || purposeIds.indexOf(id) > -1) {
            if (id >= 1 && id <= 24) {
              standardPurposeConsents[id] = consentData.isPurposeAllowed(id);
            }
            if (id >= 25 && id <= 88) {
              customPurposeConsents[id] = consentData.isPurposeAllowed(id);
            }
          }
        }
        
        var PublisherConsents = {
          metadata: consent_string,
          gdprApplies: config.gdprApplies,
          hasGlobalScope: config.hasGlobalScope,
          standardPurposeConsents: standardPurposeConsents,
          customPurposeConsents: customPurposeConsents
        };
        callback(PublisherConsents, true);
      } else {
        commands.requireConsent(null, function() {
          commands.getPublisherConsents(purposeIds, callback);
        });
      }
    }
    
    commands.getVendorList = function(vendorListVersion, callback) {
      callback(vendorList);
    };
    
    //
    // custom calls
    //
    commands.removeCookies = function(ignored, callback) {
      Utils.removePermanentCookie(config.cookie_name);
      isConsentObtained = false;
      callback && callback(true);
    }
    
    commands.requireConsent = function(ignored, callback) {
      //console.log('*** requireConsent');
      var ck_value = Utils.getPermanentCookie(config.cookie_name);
      
      // check if string is valid
      var valid = false;
      if (ck_value != "") {
        try {
          var consent_string = ck_value.split('|')[0];
          var consentData = new ConsentString(consent_string);
          valid = true;
        } catch(err) {
          // not valid
        }
      }
      
      if (!valid) {
        // se il cookie è vuoto (o non valido), mostra il banner
        commands.showBanner(ignored, callback);
        return;
      } else {
        // se il cookie è presente, ma le configurazioni di vendor o purposes sono diverse, mostra il banner
        try {
          var consent_string = ck_value.split('|')[0];
          var vendors_allowed = ck_value.split('|')[1];
          var purposes_allowed = ck_value.split('|')[2];
          if (
            vendors_allowed != config.vendors_allowed.join(',')
            || purposes_allowed != config.purposes_allowed.join(',')
          ) {
            commands.showBanner(ignored, callback);
            return;
          }
        } catch (err) {
          // in caso di errore nel formato del cookie, mostro il banner
          commands.showBanner(ignored, callback);
          return;
        }
      }
      var consentData = new ConsentString(consent_string);

      /*
      for (var i = 0; i < config.vendors_allowed.length; i++) {
        var isAllowed = consentData.isVendorAllowed(config.vendors_allowed[i]);
        if (!isAllowed) {
          commands.showBanner(ignored, callback);
          return;
        }
      }
      */
      
      isConsentObtained = true;
      callback && callback(null, true);
    }
    
    commands.showBanner = function(show_purposes, callback) {
      //console.log("showBanner: callback", callback);
      var onSubmit = function() {    
        const consentData = new ConsentString();
        consentData.setGlobalVendorList(vendorList);
        consentData.setCmpId(CMP_ID);
        consentData.setCmpVersion(CMP_VERSION);
        consentData.setConsentScreen(1);
        consentData.setConsentLanguage('it');
        consentData.setPurposesAllowed(Ui.getAllowedPurposes());
        consentData.setVendorsAllowed(Ui.getAllowedVendors());
        //consentData.setVendorAllowed([]);
        
        var ck_value = consentData.getConsentString() + '|' + config.vendors_allowed.join(',') + '|' + config.purposes_allowed.join(',');
        Utils.setPermanentCookie(config.cookie_name, ck_value, config.ms_to_expire);
        
        if (callback) 
          callback(null, true);
      }
      Ui.showBanner(onSubmit, vendorList, purposesList, config.vendors_allowed, config.purposes_allowed, config, show_purposes);
    }
    
    var onClickMiniLayer = function() {
      commands.showBanner(true, function() {
        // viene eseguita quando faccio "submit" della scelta
        Ui.showMiniLayer(onClickMiniLayer, config);
      });
    };
    
    var getConsentObject = function() {
      var ck_value = Utils.getPermanentCookie(config.cookie_name, config.ms_to_expire);
      var consent_string = ck_value.split('|')[0];
      var consentData = new ConsentString(consent_string);
      
      var purposeConsents = {};
      for (var i = 0; i < vendorList.purposes.length; i++) {
        var id = vendorList.purposes[i].id;
        purposeConsents[id] = consentData.isPurposeAllowed(id);
      }
      
      var vendorConsents = {};
      var vendorIds = vendorIds || [];
      for (var i = 0; i < vendorList.vendors.length; i++) {
        var id = vendorList.vendors[i].id;
        if (vendorIds.length == 0 || vendorIds.indexOf(id) > -1) {
          vendorConsents[id] = consentData.isVendorAllowed(id);
        }          
      }
      
      return {
        metadata: consent_string,
        gdprApplies: config.gdprApplies,
        hasGlobalScope: config.hasGlobalScope,
        purposeConsents: purposeConsents,
        vendorConsents: vendorConsents
      }
    }
    
    //
    // cmp function
    //
    var cmp = function(command, parameter, callback) {
      if (commands[command]) {
        if (vendorListLoaded == 1 && purposesListLoaded == 1) {
          commands[command](parameter, callback);
        } else {
          //console.log('lists not ready yet, queueing...');
          queue.push({
            command: command,
            parameter: parameter,
            callback: callback
          }); 
        }
      }
    } 
    isLoaded = true;
    
    // VIII. Run any queued calls using the parameters stored by the stub, in the order received.
    function processQueue() {
      for (var i = 0; i < queue.length; i++) {
        var c = queue[i];
        //console.log('processing queue.', c.command);
        cmp(c.command, c.parameter, c.callback);
      }
    }
    
    //
    // request lists
    //
    var vendorListLoaded = 0;
    var purposesListLoaded = 0;

    function tryProcessQueue() {
      //console.log("trying to process queue...");
      if (vendorListLoaded == 1 && purposesListLoaded == 1) {
        processQueue();
      }
    }

    //console.log("# loading vendor list...");
    Utils.get(config.global_vendor_list_location, function(result) {
      //console.log("### vendor list loaded.");
      vendorList = JSON.parse(result);
      //console.log(vendorList);
      vendorListLoaded = 1;
      tryProcessQueue();
    });
    
    //console.log("# loading purposes list...");
    Utils.get(config.purposes_language_location, function(result) {
      //console.log("### purposes language location loaded.");
      purposesList = JSON.parse(result);
      //console.log("purposes retrieved...", purposesList);
      purposesListLoaded = 1;
      tryProcessQueue();
    });
    
    //
    // return object
    //
    return cmp;
  })();
  
})();