
var config;
var okBtn;
var div;
var minidiv;
var purposesdiv;
var rnd = 's' + (new Date().getTime()).toString(36) + Math.random().toString(36).slice(2);      
var Ui = {};
var isShown = false;
var handlers = [];
var vendorList;     // lista globale dei vendors (non usata per ora).
var purposesList;   // lista globale dei purposes.
var featuresList;   // lista globale delle features.
var vendorsInPage;  // vendors che sono presenti in pagina di cui bisogna richiedere il consenso (come da configurazione publisher).
var purposesInPage; // purposes che sono presenti in pagina di cui bisogna richiedere il consenso (come da configurazione publisher).
var purposesState;  // stato del consenso dei purposes.
var vendorsState;   // stato del consenso dei vendors.
var vendorsAreShown = false;
//var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
var minilabel_click_action;
var consent;

function creaLayer() {          
  div = document.createElement('DIV');
  div.className = 'div_shbnr_cs_' + rnd;
 
  var first_layer_purposes = '';
  for (var i = 0; i < purposesList.length; i++) {
    var id_purpose = purposesList[i].id;
    var sw = createSwitch('purpose_' + i, purposesList[i].name, purposesState[id_purpose], 'first_layer_switch_' + i + '_' + rnd, 'first_layer_switch');
    first_layer_purposes += '<div class="first_layer_purposes_element">' + sw + '</div>';
  }
  
  var html = '';
  html += '<div class="innerdiv_shbnr_cs_' + rnd + '">';
  html += '   <div style="padding-top:10px;"><b>' + config.title + '</b></div>';
  html += '   <div>' + config.paragraph_1 + '</div>';
  html += '   <div>' + config.paragraph_2 + '</div>'; 
  html += '   <div>' + config.paragraph_3 + '</div>'; 
  html += '   <div style="padding:10px 0;">' + first_layer_purposes + '</div>'; 
  html += '   <div style="text-align:right;">';
  html += '     <button class="primaryBtn_shbnr_cs_' + rnd + ' okBtn_shbnr_cs_' + rnd + '">' + config.button_ok + '</button>';
  html += '     &nbsp;&nbsp;<button class="primaryBtn_shbnr_cs_' + rnd + ' koBtn_shbnr_cs_' + rnd + '">' + config.button_ko + '</button>';
  html += '     <p><button class="secondaryBtn_shbnr_cs_' + rnd + ' purposesBtn_shbnr_cs_' + rnd + '">' + config.button_preferences + '</button><br>';
  html += '     <button class="secondaryBtn_shbnr_cs_' + rnd + ' vendordetailMainBtn_shbnr_cs_' + rnd + '">' + config.show_vendor_label + '</button></p>';
  html += '   </div>';
  html += '</div>';
  div.innerHTML = html;

  var css = '';
  var positioning = config.posizioneinformativa == 2 ? 'bottom' : 'top';
  css += '.div_shbnr_cs_' + rnd + '{min-height:35vh;max-height:80vh;overflow:auto;font-size:14px !important;background-color:black;' + positioning + ':0px !important;left:0px !important;position: fixed;width: 100% !important;z-index: 99999998 !important;}';
  css += '.div_shbnr_cs_' + rnd + ' a:link{color:#fff;}';
  css += '.div_shbnr_cs_' + rnd + ' a:visited{color:#fff;}';
  css += '.innerdiv_shbnr_cs_' + rnd + '{font-size:14px !important;margin:0 auto;text-align:left;display:block;padding:10px;width:auto;font-family: Helvetica,Arial,FreeSans,sans-serif !important;background:#000;color:#fff;max-width: 900px;position:relative;}';
  css += '.secondaryBtn_shbnr_cs_' + rnd + '{cursor:pointer;padding: 5px 2px;min-width:120px;background-color:transparent;color:#fff;border:none;text-decoration:underline;}';
  css += '.secondaryBtn_shbnr_cs_' + rnd + ':hover{text-decoration:none;}';
  css += '.primaryBtn_shbnr_cs_' + rnd + '{cursor:pointer;line-height:normal;padding: 5px 2px;width:120px;background-color:transparent;color:#fff;border:1px solid gray;}';
  css += '.primaryBtn_shbnr_cs_' + rnd + ':hover{background-color:#666;}';
  css += '.switch *{cursor:pointer}.switch input{opacity:0;position:absolute;z-index:-1}.switch span{position:relative;display:inline-block;width:40px;height:20px;background-color:#fff;border:1px solid #ddd;border-radius:100px;transition:all .5s;box-shadow:inset 1px 2px 3px rgba(0,0,0,.1);vertical-align:middle}.switch input:checked+span{background-color:#5d9cec;border-color:#5d9cec;transition:all .5s}.switch input:checked+span:after{left:50%;transition:all .2s}.switch span:after{content:"";position:absolute;background-color:#fff;top:0;left:0;height:18px;width:18px;border:1px solid #ddd;border-radius:400px;box-shadow:1px 1px 3px rgba(0,0,0,.1);-webkit-transition:all .2s}';
  css += '.first_layer_purposes_element{display:inline-block;width:48%;line-height:30px;}';
  // media queries
  css += '@media screen and (max-width: 680px) {';
  css += '  .first_layer_purposes_element {display:block;width:auto;line-height:26px;}';
  css += '}';
  
  var style = document.createElement('STYLE');
  style.innerHTML = css;

  document.body.appendChild(style);
  document.body.appendChild(div);
};

function creaMiniLayer() {
  minidiv = document.createElement('DIV');
  minidiv.className = 'minidiv_shbnr_cs_' + rnd;
  
  var html = '';
  var positioning = config.posizioneinformativa == 2 ? 'bottom' : 'top';
  html += '<div style="z-index:1000;cursor:pointer;padding:10px 20px;font-size:10pt;font-family:Helvetica,Arial,FreeSans,sans-serif;background-color:black;color:#fff;position:fixed;' + positioning + ':0px;right:30px;">';
  html += config.mini_label;
  html += '</div>';
  minidiv.innerHTML = html;

  document.body.appendChild(minidiv);
}

function switchLabel() {
  var id = 'switch_purpose_' + rnd;
  var item = document.querySelector('#' + id);
  var label = document.querySelector('#' + id + '_label');
  label.innerHTML = label.innerHTML == config.ACTIVE_LABEL ? config.INACTIVE_LABEL : config.ACTIVE_LABEL;
  var index = item.name.split('_')[1];
  var id = purposesList[index].id;
  purposesState[id] = +item.checked;
  //console.log(purposesState);
}

function firstSwitchLabel(evt) {
  var item = evt.target;
  var index = item.name.split('_')[1];
  var id = purposesList[index].id;
  purposesState[id] = +item.checked;
}

function createSwitch(name, label, initialValue, _id, _classname) {
  var html = '';
  var id = _id || ('switch_purpose_' + rnd);
  var checked = initialValue ? 'checked' : '';
  var classname = _classname || '';

  html += '<label class="switch ' + classname +'">';
  html += '<input ' + checked + ' type="checkbox" value="1" id="' + id + '" name="' + name + '">';
  html += '<span></span> <i id="' + id + '_label">' + label + '</i>';
  html += '</label>';

  return html;
}

function hidePurposes() {
  if (vendorsAreShown) {
    vendorsAreShown = false;
    for (var i = 0; i < vendorList.length; i++) {
      var switchItem = document.querySelector('#vendor_switch_' + vendorList[i].id + '_' + rnd);
      switchItem && switchItem.removeEventListener('change', updateVendorsState);
    }
    document.querySelector('#select_shbnr_cs_' + rnd).style.display = 'block';
    document.querySelector('.purposes_desc_shbnr_cs_' + rnd).style.display = 'block';
    document.querySelector('.vendors_desc_shbnr_cs_' + rnd).innerHTML = '';
  } else {
    div.style.display = 'block';
    purposesdiv.style.display = 'none';
  }
}

function updateVendorsState(evt) {
  var id = evt.target.name.split('_')[2];
  vendorsState[id] = +evt.target.checked;
  //console.log(vendorsState);
}

function showVendors() {
  showPurposes();
  var html = '';
  
  html += '<table width="100%">';
  html += ' <thead>';
  html += '   <th>Company</th>';
  html += '   <th>' + config.consent_label + '</th>';
  html += ' </thead>';
  html += ' </tbody>';
  for (var i = 0; i < vendorList.length; i++) {
    var vendor_id = vendorList[i].id;
    html += '<tr>';
    html += ' <td style="text-align:left;">' + vendorList[i].name + '</td>';
    html += ' <td style="text-align:left;">' + createSwitch('vendor_switch_' + vendorList[i].id, '', vendorsState[vendor_id], 'vendor_switch_' + vendor_id + '_' + rnd) + '</td>';
    html += '</tr>';
    html += '<tr class="expandable">';
    html += ' <td colspan="2" style="font-size:10pt;text-align:left;">';
    html += '   <b>Policy Url:</b> <a target="_blank" href="' + vendorList[i].policyUrl + '"><font color="#FFFFFF">' + vendorList[i].policyUrl + '</font></a><br>';
    if (vendorList[i].purposeIds.length > 0)
      html += '   <b>Purposes:</b> ' + getPurposesList(vendorList[i].purposeIds) + '<br>';
    if (vendorList[i].legIntPurposeIds.length > 0)
      html += '   <b>Legitimate Interest Purposes:</b> ' + getLegIntPurposesList(vendorList[i].legIntPurposeIds) + '<br>';
    if (vendorList[i].featureIds.length > 0)
      html += '   <b>Features:</b>: ' + getFeaturesList(vendorList[i].featureIds) + '<br>';
    html += ' </td>';
    html += '</tr>';
  }
  html += ' </tbody>';
  html += '</table>';
  
  vendorsAreShown = true;
  document.querySelector('#select_shbnr_cs_' + rnd).style.display = 'none';
  document.querySelector('.purposes_desc_shbnr_cs_' + rnd).style.display = 'none';
  document.querySelector('.vendors_desc_shbnr_cs_' + rnd).innerHTML = html;
  // eventi
  for (var i = 0; i < vendorList.length; i++) {
    document.querySelector('#vendor_switch_' + vendorList[i].id + '_' + rnd).addEventListener('change', updateVendorsState);
  }
}

function getPurposeById(id) {
  for (var i = 0; i < purposesList.length; i++) {
    if (purposesList[i].id == id)
      return purposesList[i];
  }
}

function getFeatureById(id) {
  for (var i = 0; i < featuresList.length; i++) {
    if (featuresList[i].id == id)
      return featuresList[i];
  }
}

function getPurposesList(ids) {
  var lis = [];
  for (var i = 0; i < ids.length; i++) {
    lis.push('&nbsp;&nbsp;' + getPurposeById(ids[i]).name);
  }
  return '<div>' + lis.join('<br>') + '</div>';
}

function getLegIntPurposesList(ids) {
  var lis = [];
  for (var i = 0; i < ids.length; i++) {
    lis.push('&nbsp;&nbsp;' + getPurposeById(ids[i]).name);
  }
  return '<div>' + lis.join('<br>') + '</div>';
}

function getFeaturesList(ids) {
  var lis = [];
  for (var i = 0; i < ids.length; i++) {
    var f = getFeatureById(ids[i]);
    lis.push('&nbsp;&nbsp;' + f.name + ' <i>(' + f.description + ')</i>');
  }
  return '<div>' + lis.join('<br>') + '</div>';
}

function renderPurpose(evt) {
  // id dello switch
  var id = 'switch_purpose_' + rnd;
  var item = document.querySelector('#' + id);
  item && item.removeEventListener('change', switchLabel);
  item && document.querySelector('.vendordetailBtn_shbnr_cs_' + rnd).removeEventListener('click', showVendors);
  
  // set value
  var value = 0;
  if (evt) {
    value = this.dataset.value;
  }
  
  // render selected option
  var selectors = document.querySelectorAll('#select_shbnr_cs_' + rnd + ' .option');
  for (var i = 0; i < selectors.length; i++) {
    selectors[i].className = selectors[i].dataset.value == value ? "option selected" : "option";
  }
  
  var id_purpose = purposesList[value].id;
  var html = '';
  html += '<b>' + purposesList[value].name + '</b>';
  html += '<p>' + createSwitch('purpose_' + value, purposesState[id_purpose] == 0 ? config.INACTIVE_LABEL : config.ACTIVE_LABEL, purposesState[id_purpose]) + '</p>';
  html += '<p>' + purposesList[value].description + '</p>';
  html += '<p><button class="secondaryBtn_shbnr_cs_' + rnd + ' vendordetailBtn_shbnr_cs_' + rnd + '">' + config.show_vendor_label + '</button></p>';
  document.querySelector('.purposes_desc_shbnr_cs_' + rnd).innerHTML = html;
  
  // eventi
  document.querySelector('#' + id).addEventListener('change', switchLabel);
  document.querySelector('.vendordetailBtn_shbnr_cs_' + rnd).addEventListener('click', showVendors);
}

function showPurposes() {
  div.style.display = 'none'; // the banner div
  if (!purposesdiv) {
    purposesdiv = document.createElement('DIV');
    purposesdiv.className = 'purposesdiv_shbnr_cs_' + rnd;
    
    var html = '';
    
    html += '<div class="innerdiv_header_shbnr_cs_' + rnd + '">';
    html += '   ' + config.details_title;
    html += '</div>';
    html += '<div class="innerdiv_body_shbnr_cs_' + rnd + '">';
    
    html += '   <div class="select" id="select_shbnr_cs_' + rnd + '">';
    for (var i = 0; i < purposesList.length; i++) {
      html += '<div class="option" data-value="' + i + '">' + purposesList[i].name + '</div>';
    }
    html += '   </div>';
    
    html += '   <div class="purposes_desc_shbnr_cs_' + rnd + '"></div>';
    html += '   <div class="vendors_desc_shbnr_cs_' + rnd + '"></div>';
    html += '</div>';
    html += '<div class="innerdiv_footer_shbnr_cs_' + rnd + '">';
    html += '   <button class="secondaryBtn_shbnr_cs_' + rnd + ' backBtn_shbnr_cs_' + rnd + '">' + config.back_label + '</button>';
    html += '   &nbsp;&nbsp;<button class="primaryBtn_shbnr_cs_' + rnd + ' customizeBtn_shbnr_cs_' + rnd + '">' + config.customize_label + '</button>&nbsp;&nbsp;';
    html += '</div>';
    
    purposesdiv.innerHTML = html;
    
    var css = '';
    css += '.purposesdiv_shbnr_cs_' + rnd + '{width:640px;height:480px;left:calc(50% - 320px);top:40px;font-size:14px !important;background-color:black;position: fixed;z-index: 99999999 !important;}';
    css += '.purposesdiv_shbnr_cs_' + rnd + ' table tr td{background-color:black !important;}';
    css += '.purposesdiv_shbnr_cs_' + rnd + ' table tr th{background-color:black !important;}';
    css += '.innerdiv_header_shbnr_cs_' + rnd + '{line-height:36px;font-family: Helvetica,Arial,FreeSans,sans-serif !important;position:absolute;width:100%;height:40px;border-top:1px solid white;text-align:center;left:0;top:0;color:#fff;}';
    css += '.innerdiv_body_shbnr_cs_' + rnd + '  {color:white;position:absolute;width:100%;height:398px;border-top:1px solid white;text-align:left;left:0;top:40px;overflow-y:scroll;}';
    css += '.innerdiv_body_shbnr_cs_' + rnd + ' .select,  .innerdiv_body_shbnr_cs_' + rnd + ' #select_shbnr_cs_' + rnd + ' {padding:0;float:left;outline:none;width:200px;background-color:black;color:white;overflow:hidden;border:none;}';
    css += '.innerdiv_body_shbnr_cs_' + rnd + ' .select .option, .innerdiv_body_shbnr_cs_' + rnd + ' #select_shbnr_cs_' + rnd + ' .option {font-family:Helvetica,Arial,FreeSans,sans-serif;font-size:13px;cursor:pointer;padding:15px;border-bottom:1px solid white;border-right:1px solid white;white-space:normal;}';
    css += '.innerdiv_body_shbnr_cs_' + rnd + ' #select_shbnr_cs_' + rnd + ' .option.selected {background-color:rgb(200, 200, 200);color:#333;}';
    css += '.purposes_desc_shbnr_cs_' + rnd + ' {font-family: Helvetica,Arial,FreeSans,sans-serif !important;margin-left:210px;margin-top:10px;margin-right:10px;}';
    css += '.purposes_desc_shbnr_cs_' + rnd + ' i {font-family: Helvetica,Arial,FreeSans,sans-serif !important;}';
    css += '.vendors_desc_shbnr_cs_' + rnd + ' {padding:10px;font-family: Helvetica,Arial,FreeSans,sans-serif !important;}';
    css += '.vendors_desc_shbnr_cs_' + rnd + ' table {border:none;border-collapse:collapse;}';
    css += '.vendors_desc_shbnr_cs_' + rnd + ' table thead {background-color: transparent;}';
    css += '.vendors_desc_shbnr_cs_' + rnd + ' table th {font-size:14px;text-align:left;background-color:transparent;color:#fff;padding:0;font-weight:bold;}';
    css += '.vendors_desc_shbnr_cs_' + rnd + ' table td {font-size:14px;color:#fff;padding:10px 5px;border-bottom:1px solid #333;}';
    css += '.innerdiv_footer_shbnr_cs_' + rnd + '{line-height:36px;position:absolute;width:100%;height:40px;border-top:1px solid white;text-align:right;left:0;bottom:0;}';
    // media queries
    css += '@media screen and (max-width: 680px) {';
    css += '  .purposesdiv_shbnr_cs_' + rnd + '{width:100%;left:0;}';
    css += '  .innerdiv_body_shbnr_cs_' + rnd + ' .select, .innerdiv_body_shbnr_cs_' + rnd + ' #select_shbnr_cs_' + rnd + ' {width:120px;font-size:12px;}';
    css += '  .purposes_desc_shbnr_cs_' + rnd + ' {margin-left:130px;font-size:12px;}';
    css += '}';
    
    var style = document.createElement('STYLE');
    style.innerHTML = css;
    
    document.body.appendChild(style);
    document.body.appendChild(purposesdiv);
    
    document.querySelector('.backBtn_shbnr_cs_' + rnd).addEventListener('click', hidePurposes);
    document.querySelector('.customizeBtn_shbnr_cs_' + rnd).addEventListener('click', customizeHandler);
    
    var selectors = document.querySelectorAll('#select_shbnr_cs_' + rnd + ' .option');
    for (var i = 0; i < selectors.length; i++) {
      selectors[i].addEventListener('click', renderPurpose);
    }
    renderPurpose();

  } else {
    purposesdiv.style.display = 'block';
  }
}

function customizeHandler() {
  mainHandler();
}

function acceptAllHandler() {
  for (var purpose_id in purposesState) {
    purposesState[purpose_id] = 1;
  }  
  for (var vendor_id in vendorsState) {
    vendorsState[vendor_id] = 1;
  }  
  mainHandler();
}

function denyAllHandler() {
  for (var purpose_id in purposesState) {
    purposesState[purpose_id] = 0;
  }  
  for (var vendor_id in vendorsState) {
    vendorsState[vendor_id] = 0;
  }  
  mainHandler();
}

function checkScroll(evt) {
  if (window.pageYOffset > config.SCROLL_TO_CONSENT) {
    setTimeout(function() {
      if (div.parentNode) {
        window.removeEventListener('scroll', checkScroll);    
        acceptAllHandler();
      }
    }, config.SCROLL_DELAY);
  }
}
      
function mainHandler() {
  // esegue gli handlers accodati
  for (var i = 0; i < handlers.length; i++) {
    handlers[i]();
  }
  handlers = [];
  
  // rimuove il banner
  if (div && div.parentNode) {
    for (var i = 0; i < handlers.length; i++) {
      document.querySelector('.okBtn_shbnr_cs_' + rnd).removeEventListener('click', acceptAllHandler);
      document.querySelector('.koBtn_shbnr_cs_' + rnd).removeEventListener('click', denyAllHandler);
      document.querySelector('.purposesBtn_shbnr_cs_' + rnd).removeEventListener('click', showPurposes);
      document.querySelector('.vendordetailMainBtn_shbnr_cs_' + rnd).removeEventListener('click', showVendors);
      document.querySelector('.backBtn_shbnr_cs_' + rnd).removeEventListener('click', hidePurposes);

      var selectors = document.querySelectorAll('#select_shbnr_cs_' + rnd + ' .option');
      for (var i = 0; i < selectors.length; i++) {
        selectors[i].removeEventListener('click', renderPurpose);
      }    

      var selectors = document.querySelectorAll('.first_layer_switch input');
      for (var i = 0; i < selectors.length; i++) {
        selectors[i].removeEventListener('click', firstSwitchLabel);
      }
    
      document.querySelector('.customizeBtn_shbnr_cs_' + rnd).removeEventListener('click', customizeHandler);
    }
    div.parentNode.removeChild(div);
    purposesdiv && purposesdiv.parentNode.removeChild(purposesdiv);
    purposesdiv = null;
    isShown = false;
  }
};

function minilabelClickHandler() {
  // rimuove il minibanner
  if (minidiv && minidiv.parentNode) {
    document.querySelector('.minidiv_shbnr_cs_' + rnd).removeEventListener('click', minilabelClickHandler);
    minidiv.parentNode.removeChild(minidiv);
  }
  minilabel_click_action();
}
      
Ui.showBanner = function(onSubmit, _vendorList, _purposesList, _vendorsInPage, _purposesInPage, _config, show_purposes) {
  config = _config;
  vendorsInPage = _vendorsInPage;
  purposesInPage = _purposesInPage;
  purposesList = _purposesList.purposes.filter(function(p) {
    return _purposesInPage.indexOf(p.id) != -1;
  });
  featuresList = _purposesList.features;
  vendorList = _vendorList.vendors.filter(function(v) {
    return _vendorsInPage.indexOf(v.id) != -1;
  });
  if (!purposesState) {
    purposesState = {};
    for (var i = 0; i < purposesList.length; i++) {
      var id = purposesList[i].id;
      purposesState[id] = consent ? consent.purposeConsents[id] : 0;
    }
  }
  if (!vendorsState) {
    vendorsState = {};
    for (var i = 0; i < vendorList.length; i++) {
      var id = vendorList[i].id;
      vendorsState[id] = consent ? consent.vendorConsents[id] : 0;
    }
  }
  if (!isShown) {
    creaLayer();
    document.querySelector('.okBtn_shbnr_cs_' + rnd).addEventListener('click', acceptAllHandler);
    document.querySelector('.koBtn_shbnr_cs_' + rnd).addEventListener('click', denyAllHandler);
    if (config.SCROLL_TO_CONSENT > 0)
      window.addEventListener('scroll', checkScroll);
    document.querySelector('.purposesBtn_shbnr_cs_' + rnd).addEventListener('click', showPurposes);
    document.querySelector('.vendordetailMainBtn_shbnr_cs_' + rnd).addEventListener('click', showVendors);
    var selectors = document.querySelectorAll('.first_layer_switch input');
    for (var i = 0; i < selectors.length; i++) {
      selectors[i].addEventListener('click', firstSwitchLabel);
    }
    isShown = true;
  }
  handlers.push(onSubmit);
  
  if (show_purposes)
    showPurposes();
};

Ui.showMiniLayer = function(onClick, _config) {  
  if (!(minidiv && minidiv.parentNode)) {
    minilabel_click_action = onClick;
    config = _config;
    creaMiniLayer();
    document.querySelector('.minidiv_shbnr_cs_' + rnd).addEventListener('click', minilabelClickHandler);
  }
}

Ui.getAllowedPurposes = function() {
  var allowedPurposes = [];
  for (purpose_id in purposesState) {
    if (purposesState[purpose_id] == 1) {
      allowedPurposes.push(+purpose_id);
    }
  }
  return allowedPurposes;
};

Ui.getAllowedVendors = function() {
  var allowedVendors = [];
  for (vendor_id in vendorsState) {
    if (vendorsState[vendor_id] == 1) {
      allowedVendors.push(+vendor_id);
    }
  }
  return allowedVendors;
};

Ui.setConsent = function(_consent) {
  consent = _consent;
}

module.exports = {
  Ui: Ui
};